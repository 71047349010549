// Here you can add other styles
@import "~vazir-font/dist/font-face.css";
@import "card";
@import "card";

.form-control {
  color: black !important;
}
.form-control:focus {
  color: black !important;
}

.DatePicker {
  @extend .form-control;
  text-align: center !important;
  padding: 0px !important;
  margin: 0px !important;
  z-index: unset !important;
  height: 38px !important;
}
.DatePicker input {
  border: 0px !important;
  text-align: center !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0px !important;
  margin: 0px !important;
}
.DatePicker div {
  z-index: 9999999 !important;
}
.form-data {
  @extend .form-control;
  background-color: #e9ecef;
  opacity: 1;
}
.select {
  &__control--is-disabled {
    background-color: #d8dbe0 !important;
    opacity: 1 !important;
    color: black !important;
  }
  &__single-value--is-disabled {
    color: black !important;
  }
}
.blured {
  -webkit-filter: blur(0.7px);
  -moz-filter: blur(0.7px);
  -o-filter: blur(0.7px);
  -ms-filter: blur(0.7px);
  filter: blur(0.7px);
  pointer-events: none;
}
.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 0.5rem;
}

@media (min-width: 768px) {
  .c-main > .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media (min-width: 768px) {
  .c-main > .container-fluid, .c-main > .container-sm, .c-main > .container-md, .c-main > .container-lg, .c-main > .container-xl, .c-main > .container-xxl {
    padding-right: 5px;
    padding-left: 5px;
  }
}





/** Custom BTN */


.btn-outline-primary {
  color: #fff;
  background-color: #0e3074;
  border-color: #0e3074;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .btn-outline-primary:hover {
    color: #0e3074;
    background-color: #fff;
    border-color: #0e3074;
  }
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0e3074;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #0e3074;
  border-color: #0e3074;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.5);
}

.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0e3074;
  border-color: #0e3074;
}

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.5);
}






.btn-primary {
  color: #fff;
  background-color: #0e3074;
  border-color: #0e3074;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .btn-primary:hover {
    color: #0e3074;
    background-color: #fff;
    border-color: #0e3074;
  }
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2a1ab9;
  border-color: #2819ae;
  box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0e3074;
  border-color: #0e3074;
}

.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
}

.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(81, 65, 224, 0.5);
}




.OrderBS {
  text-align: center; display: block;background-color: #f9b115;border: solid 1px;border-radius: 5px;padding-top: 6px;
}

.tlgGroupHeight {
  height: 38px !important;
}

.card-body {
  padding: 0.5rem;
}

.card {
  margin-bottom: 0.5rem;
}
