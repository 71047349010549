// Variable overrides

$font-family-base: Vazir;

// Sidebar

$sidebar-light-nav-link-hover-color: #fff;
$sidebar-light-nav-link-hover-icon-color: #fff;
$sidebar-light-nav-link-hover-bg: #39f;
$sidebar-light-nav-link-active-icon-color: #39f;
$sidebar-light-nav-link-active-color: #39f;
$sidebar-light-brand-bg: #39f;
$sidebar-light-brand-color: #fff;
