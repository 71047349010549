// Card
$card-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05) !default; // card custom shadow

// Base
.card.card-custom {
  // Make full height
  &.card-stretch {
    display: flex;
    align-items: stretch !important;
    flex-direction: column;
    height: 100%;

    &.gutter-b {
      height: calc(100% - #{$grid-gutter-width});
    }

    &.card-stretch-half {
      height: 50%;

      &.gutter-b {
        height: calc(50% - #{$grid-gutter-width});
      }
    }

    &.card-stretch-third {
      height: 33.33%;

      &.gutter-b {
        height: calc(33.33% - #{$grid-gutter-width});
      }
    }

    &.card-stretch-fourth {
      height: 25%;

      &.gutter-b {
        height: calc(25% - #{$grid-gutter-width});
      }
    }
  }

  // Card header fit style
  &.card-fit {
    > .card-header {
      border-bottom: 0;
    }

    > .card-footer {
      border-top: 0;
    }
  }

}
